import { getPublicConfig } from "@/helpers/getPublicConfig";
import type { AppRouter } from "@princess/api";
import * as Sentry from "@sentry/nextjs";
import {
  TRPCClientError,
  createTRPCProxyClient,
  httpBatchLink,
} from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import superjson from "superjson";

const { authHeaderKey } = getPublicConfig();

// https://trpc.io/docs/server/infer-types#infer-trpclienterrors-based-on-your-router
function isTRPCClientError(
  cause: unknown,
): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}

const getToken = () => {
  const authorization = window.localStorage.getItem(authHeaderKey) ?? "";
  return authorization;
};

export const trpc = createTRPCNext<AppRouter>({
  config() {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      transformer: superjson,
      queryClientConfig: {
        defaultOptions: {
          mutations: {
            onError: (error) => {
              if (isTRPCClientError(error)) {
                const { code, path } = error.data ?? {};
                Sentry.captureException(error, {
                  tags: {
                    fn: "trpc.mutation.error",
                    errorCode: code,
                    queryPath: path,
                  },
                });
              }
            },
          },
          queries: {
            onError: (error) => {
              if (isTRPCClientError(error)) {
                const { code, path } = error.data ?? {};

                Sentry.captureException(error, {
                  tags: {
                    fn: "trpc.query.error",
                    errorCode: code,
                    queryPath: path,
                  },
                });
              }
            },
          },
        },
      },
      links: [
        httpBatchLink({
          url: "/api/trpc",
          headers() {
            return {
              authorization: getToken(),
            };
          },
        }),
      ],
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
});

const trpcClient = createTRPCProxyClient<AppRouter>({
  // trpc client cannot use relative path like app.ts
  links: [
    httpBatchLink({
      url: "/api/trpc",
      headers() {
        return {
          authorization: getToken(),
        };
      },
    }),
  ],
  transformer: superjson,
});

import * as React from "react";
import { format } from "date-fns";
import { type ControllerRenderProps } from "react-hook-form";
import {
  type DropdownProps,
  type DayPickerSingleProps,
} from "react-day-picker";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "@princess/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@princess/ui/popover";
import { Calendar } from "@princess/ui/calendar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@princess/ui/select";
type Preset = "birthday";
type CalendarClassNames = React.ComponentProps<typeof Calendar>["classNames"];
import { HiOutlineCalendar } from "react-icons/hi";
import { HiChevronLeft } from "react-icons/hi";
import { HiChevronRight } from "react-icons/hi";

export interface DatePickerComponentProps extends ControllerRenderProps {
  preset?: Preset;
  placeholder?: string;
  dayPickerProps?: DayPickerSingleProps;
  classes?: {
    trigger?: string;
  };
  isOnDialog?: boolean;
}

const getDayPickerProps = (preset?: Preset): Partial<DayPickerSingleProps> => {
  switch (preset) {
    case "birthday":
      return {
        fromYear: new Date().getFullYear() - 100,
        toYear: new Date().getFullYear(),
        captionLayout: "dropdown-buttons",
        disabled: (date) => date > new Date() || date < new Date("1900-01-01"),
      };
    default:
      return {};
  }
};

export const DatePicker = React.forwardRef<
  HTMLButtonElement,
  DatePickerComponentProps
>((props, ref) => {
  const {
    onChange,
    value,
    placeholder,
    dayPickerProps,
    preset,
    classes = {},
    isOnDialog = false,
  } = props;

  const { classNames: dayPickerClassNames = {}, components = {} } =
    dayPickerProps ?? {};

  const customDayPickerProps = getDayPickerProps(preset);

  const calendarComponents = {
    Dropdown: ({ value, onChange, children }: DropdownProps) => {
      const options = React.Children.toArray(children) as React.ReactElement<
        React.HTMLProps<HTMLOptionElement>
      >[];
      const selected = options.find((child) => child.props.value === value);
      const handleChange = (value: string) => {
        const changeEvent = {
          target: { value },
        } as React.ChangeEvent<HTMLSelectElement>;
        onChange?.(changeEvent);
      };
      return (
        <Select
          value={value?.toString()}
          onValueChange={(value) => {
            handleChange(value);
          }}
        >
          <SelectTrigger className="border-none bg-transparent p-0 focus:ring-0 focus:ring-offset-0">
            <SelectValue>{selected?.props?.children}</SelectValue>
          </SelectTrigger>
          <SelectContent position="popper" className="max-h-32">
            {options.map((option, id: number) => (
              <SelectItem
                key={`${option.props.value}-${id}`}
                value={option.props.value?.toString() ?? ""}
              >
                {option.props.children}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    },
    IconLeft: () => <HiChevronLeft className="h-4 w-4" />,
    IconRight: () => <HiChevronRight className="h-4 w-4" />,
    ...components,
  };

  const calendarClassNames: CalendarClassNames = {
    ...dayPickerClassNames,
    // container classes
    months: cn("space-x-0", dayPickerClassNames.months),
    month: cn("mb-2 space-y-0", dayPickerClassNames.month),

    // month control classes
    nav_button: cn(
      buttonVariants({ variant: "outline" }),
      "h-5 w-5 border-0 bg-transparent p-0 opacity-50 hover:opacity-100",
      dayPickerClassNames.nav_button,
    ),
    caption: cn(
      "relative flex items-center justify-between bg-gray-100 px-4 pt-2",
      dayPickerClassNames.caption,
    ),
    caption_dropdowns: cn(
      "flex space-x-2",
      dayPickerClassNames.caption_dropdowns,
    ), // dropdown container

    // weekday display classes
    head_row: cn("flex bg-gray-100 px-5 py-1", dayPickerClassNames.head_row),
    head_cell: cn(
      "text-muted-foreground w-7 text-xs font-normal",
      dayPickerClassNames.head_cell,
    ),

    // day cell classes
    row: cn("mt-2 flex w-full px-5", dayPickerClassNames.row),
    day: cn(
      buttonVariants({ variant: "ghost" }),
      "h-7 w-7 p-0 font-normal text-gray-700 hover:bg-gray-100 aria-selected:opacity-100",
      dayPickerClassNames.day,
    ),
  };

  return (
    // https://github.com/shadcn-ui/ui/issues/1511#issuecomment-1810682366
    <Popover modal={isOnDialog}>
      <PopoverTrigger
        asChild
        className={cn(
          "focus:border-primary hover:bg-transparent hover:shadow-none focus-visible:ring-0",
          !value && "hover:text-muted-foreground",
          classes.trigger,
        )}
      >
        <Button
          ref={ref}
          variant={"outline"}
          className={cn(
            "h-auto w-full justify-between px-3 text-left text-base font-normal text-gray-900",
            !value && "text-muted-foreground",
          )}
        >
          {value ? (
            format(value, "PPP")
          ) : (
            <span>{placeholder ?? "Choose"}</span>
          )}
          <HiOutlineCalendar className="ml-2 h-5 w-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={value}
          onSelect={onChange}
          initialFocus
          className="p-0"
          classNames={calendarClassNames}
          components={calendarComponents}
          {...customDayPickerProps}
          {...dayPickerProps}
        />
      </PopoverContent>
    </Popover>
  );
});

DatePicker.displayName = "DatePicker";

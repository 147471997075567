import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Camera } from "lucide-react";
import { useImageDropzone } from "@/oldFeatures/common/components/SingleImageUploadField";
import { Label } from "@princess/ui/label";
import { IconButton } from "@princess/ui/custom/iconButton";
import { Text } from "@princess/ui/custom/text";
import { cn } from "@/lib/utils";

export type Avatars = {
  avatarFull?: string;
  avatarHalf?: string;
  avatarHead?: string;
};

type ImageFile = { file: File; url: string };

type AvataUploaderGroupProps = {
  avatarFull?: string;
  avatarHalf?: string;
  avatarHead?: string;
  setAvatar: (avatar: Avatars) => void;
  error?: string;
};
export const AvatarUploaderGroup = ({
  avatarFull,
  avatarHalf,
  avatarHead,
  setAvatar,
  error,
}: AvataUploaderGroupProps) => {
  const { t } = useTranslation(["user", "common"]);

  const avatarUploaders: Array<AvatarUploaderProps> = [
    {
      placeholder: "/assets/images/profile_head.png",
      onChange: (file?: ImageFile) => setAvatar({ avatarHead: file?.url }),
      preview: avatarHead,
    },
    {
      placeholder: "/assets/images/profile_half.png",
      onChange: (file?: ImageFile) => setAvatar({ avatarHalf: file?.url }),
      preview: avatarHalf,
    },
    {
      placeholder: "/assets/images/profile_full.png",
      onChange: (file?: ImageFile) => setAvatar({ avatarFull: file?.url }),
      preview: avatarFull,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Label>{t("avatar.label")}</Label>
      <div className="flex gap-3">
        {avatarUploaders.map(({ placeholder, onChange, preview }, i) => (
          <AvatarUploader
            key={i}
            onChange={onChange}
            preview={preview}
            placeholder={placeholder}
          />
        ))}
      </div>
      {error && (
        <Text className="text-red-500" size="sm">
          {error}
        </Text>
      )}
    </div>
  );
};

type AvatarUploaderProps = {
  onChange: (file?: ImageFile) => void;
  preview: string | undefined;
  placeholder: string;
};

const AvatarUploader = ({
  onChange,
  preview,
  placeholder,
}: AvatarUploaderProps) => {
  const { getInputProps, getRootProps, open } = useImageDropzone(onChange, {
    noClick: true,
    noDrag: true,
  });

  return (
    <div
      className={cn(
        "relative h-24 w-24 overflow-hidden rounded-lg bg-gray-100",
        !preview && "p-3",
      )}
      {...getRootProps()}
    >
      <div className="relative h-full w-auto">
        <Image
          src={preview ?? placeholder}
          fill
          className="object-cover object-center"
          alt="avatar upload"
        />
      </div>
      <div className="absolute bottom-1 right-1">
        <IconButton
          className="h-fit w-fit rounded-full bg-white p-2 shadow-md shadow-gray-500 transition duration-300 ease-in-out hover:bg-gray-50 hover:opacity-100"
          onClick={open}
          type="button"
        >
          <button>
            <Camera className="text-primary h-8 w-8 hover:opacity-50" />
          </button>
        </IconButton>
      </div>
      <input {...getInputProps()} />
    </div>
  );
};

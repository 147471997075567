import { getUserFolder } from "@/helpers/utils";
import {
  AgeVerifiedIcon,
  RateVerifiedIcon,
} from "@/oldFeatures/common/components";
import { Avatar, makeStyles } from "@material-ui/core";
import {
  UserRateLevelEnum,
  UserVerifyEnum,
} from "@princess/graphql-codegen/graphql-hooks";
import React, { type FC } from "react";

interface ImageDisplayProps {
  userId?: number | string | null;
  logo?: string | null;
  variant?: "shadow" | "border" | "none";
  shape?: "square" | "circle";
  size?: "l" | "m" | "s";
  ageVerify?: UserVerifyEnum;
  rateVerify?: UserRateLevelEnum;
  showIcon?: boolean;
}

const useImageDisplayStyles = makeStyles((theme) => ({
  sizeL: { width: 128, height: 128 },
  sizeM: { width: 96, height: 96 },
  sizeS: { width: 64, height: 64 },
  square: {
    borderRadius: 8,
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: "dotted",
  },
  squareShadow: { boxShadow: `0px 0px 4px 4px ${theme.palette.primary.light}` },
  circleShadow: { boxShadow: `0px 0px 2px 2px ${theme.palette.primary.light}` },
  image: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  iconContainer: {},
}));

const getBackgroundImageUrl = (
  logo: string | null | undefined,
  folder: string,
  isCloudinary: boolean,
) => {
  return isCloudinary ||
    logo?.startsWith("data:") ||
    logo?.includes("/static/media")
    ? logo
    : folder + logo;
};

/**
 *  @deprecated this is using material UI
 */
export const ImageDisplay: FC<ImageDisplayProps> = ({
  userId,
  logo,
  variant = "border",
  shape = "square",
  size = "m",
  ageVerify,
  rateVerify,
  showIcon,
}) => {
  const classes = useImageDisplayStyles();
  const folder = userId ? getUserFolder(userId as string) : "";
  const sizeClasses =
    size === "l" ? classes.sizeL : size === "m" ? classes.sizeM : classes.sizeS;
  const isCloudinary = Boolean(logo?.includes("cloudinary"));
  const backgroundImageUrl = getBackgroundImageUrl(logo, folder, isCloudinary);
  return shape === "square" ? (
    <div
      className={`${sizeClasses} ${classes.square} ${classes.image}`}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      {((ageVerify && ageVerify === UserVerifyEnum.Accepted) ||
        (rateVerify && rateVerify !== UserRateLevelEnum.Normal)) &&
      showIcon ? (
        <div
          style={{
            height: 20,
            borderRadius: "0px 0px 0px 8px",
            float: "right",
            backgroundColor: "rgba(255,255,255,0.8)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            paddingTop: 3,
            paddingLeft: 3,
          }}
        >
          {ageVerify && ageVerify === UserVerifyEnum.Accepted ? (
            <AgeVerifiedIcon
              height={20}
              width={20}
              style={{
                height: 17,
                width: 16,
                color: "#E24DA9",
              }}
            ></AgeVerifiedIcon>
          ) : null}
          {rateVerify && rateVerify !== UserRateLevelEnum.Normal ? (
            <RateVerifiedIcon
              height={20}
              width={20}
              style={{
                height: 17,
                width: 16,
                color: "#E24DA9",
              }}
            ></RateVerifiedIcon>
          ) : null}
        </div>
      ) : null}
    </div>
  ) : (
    <Avatar
      className={`${sizeClasses} ${
        variant === "shadow" && classes.circleShadow
      }`}
      src={(isCloudinary ? logo : `${folder}${logo}`) ?? undefined}
    />
  );
};

import { Route } from "@/consts/Route";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Text } from "@princess/ui/custom/text";
import { cn } from "@/helpers/cn";

export const TncAndPrivacyLinks = ({
  textClassName,
}: {
  textClassName?: string;
}) => {
  const { t } = useTranslation("common");
  const sharedClassName = cn("text-sm", textClassName);

  return (
    <div className="inline">
      <Link
        className="underline hover:opacity-70"
        href={Route.Tnc}
        rel="noreferrer noopener"
      >
        <Text className={sharedClassName}>{t("tnc_privacy.tnc")}</Text>
      </Link>
      <Text className={sharedClassName}>{t("and")}</Text>
      <Link
        className="underline hover:opacity-70"
        href={Route.Privacy}
        rel="noreferrer noopener"
      >
        <Text className={sharedClassName}>{t("tnc_privacy.privacy")}</Text>
      </Link>
    </div>
  );
};

import AgeVerified from "public/assets/icons/age_verified.svg";
import RateVerified from "public/assets/icons/rate_verified.svg";
import Stripe from "public/assets/icons/stripe.svg";
import SvgIcon, { type SvgIconProps } from "@material-ui/core/SvgIcon";
import React, { type FC, type SVGProps } from "react";
import { GiWineGlass } from "react-icons/gi";
import { type IconBaseProps } from "react-icons/lib";

export const WineIcon = (props: IconBaseProps) => {
  return <GiWineGlass {...props} />;
};

/**
 *  @deprecated this is using material UI
 */
export const AgeVerifiedIcon = (props: SvgIconProps) => (
  <SvgIcon color="primary" {...props}>
    <AgeVerified width="100%" height="100%" />
  </SvgIcon>
);

/**
 *  @deprecated this is using material UI
 */
export const RateVerifiedIcon = (props: SvgIconProps) => (
  <SvgIcon color="primary" {...props}>
    <RateVerified width="100%" height="100%" />
  </SvgIcon>
);

/**
 *  @deprecated this is using material UI
 */
export const StripeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <Stripe width="100%" height="100%" />
    </SvgIcon>
  );
};

/**
 *  @deprecated this is using material UI
 */
export const WineInputIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"svg-icon fill-current " + className}
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M7.33317 11.5785V13.703L5.99926 14.453V15.0369H10.001V14.453L8.66708 13.703V11.5785C10.776 11.3104 12.5681 9.80442 13.1444 7.71485C13.3204 7.0799 13.3524 6.36093 13.2397 5.57659L12.6621 1.5355C12.6394 1.37664 12.56 1.23131 12.4388 1.1262C12.3175 1.02109 12.1624 0.963235 12.0019 0.963257H3.99839C3.8379 0.963235 3.68278 1.02109 3.56149 1.1262C3.44021 1.23131 3.3609 1.37664 3.33811 1.5355L2.76119 5.57592C2.64847 6.36093 2.68049 7.0799 2.85656 7.71418C3.43215 9.80442 5.22426 11.3104 7.33317 11.5785ZM11.4236 2.29717L11.8038 4.96499H4.19581L4.57664 2.29717H11.4236Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="15" height="15" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

/**
 *  @deprecated this is using material UI
 */
export const CrownNewIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 81 80"
      xmlns="http://www.w3.org/2000/svg"
      className={"svg-icon fill-current " + className}
      {...props}
    >
      <path d="M40.5 7.5C36.3875 7.5 33 10.8875 33 15C33 17.8125 34.5825 20.2825 36.9075 21.5625L29.56 36.25L18.075 28.0475C19.5825 26.67 20.5 24.675 20.5 22.5C20.5 18.3875 17.1125 15 13 15C8.8875 15 5.5 18.3875 5.5 22.5C5.5 25.87 7.815 28.6725 10.89 29.61L15.5 55V67.5H65.5V55L70.11 29.61C73.185 28.6725 75.5 25.87 75.5 22.5C75.5 18.3875 72.1125 15 68 15C63.8875 15 60.5 18.3875 60.5 22.5C60.5 24.6775 61.4175 26.67 62.9225 28.0475L51.4375 36.25L44.0925 21.5625C45.2712 20.9181 46.2555 19.9693 46.9428 18.8151C47.6301 17.6608 47.9952 16.3434 48 15C48 10.8875 44.6125 7.5 40.5 7.5ZM40.5 12.5C41.9075 12.5 43 13.595 43 15C43 16.4075 41.905 17.5 40.5 17.5C39.0925 17.5 38 16.405 38 15C38 13.5925 39.095 12.5 40.5 12.5ZM13 20C14.4075 20 15.5 21.095 15.5 22.5C15.5 23.9075 14.405 25 13 25C11.5925 25 10.5 23.905 10.5 22.5C10.5 21.0925 11.595 20 13 20ZM68 20C69.4075 20 70.5 21.095 70.5 22.5C70.5 23.9075 69.405 25 68 25C66.5925 25 65.5 23.905 65.5 22.5C65.5 21.0925 66.595 20 68 20ZM40.5 25.625L48.235 41.095L51.985 42.0325L64.485 33.125L60.9675 52.5H20.0325L16.5175 33.125L29.0175 42.0325L32.7675 41.095L40.5 25.625ZM20.5 57.5H60.5V62.5H20.5V57.5Z" />
    </svg>
  );
};

import { useUserContext } from "@/oldFeatures/auth/hooks";
import { CloudinaryEvent, useInjectedLoggers } from "@/oldFeatures/ga";
import {
  type FeatureEnum,
  uploadImage,
} from "@/services/cloudinary/uploadImages";
import { type CloudinaryImageType } from "@princess/helpers/src/cloudinary";
import * as Sentry from "@sentry/nextjs";

export const useUploadImageCloudinary = () => {
  const { logEvent } = useInjectedLoggers();
  const { user } = useUserContext();
  const uploadImageToCloudinary = async (
    file: File,
    type: CloudinaryImageType,
    feature: FeatureEnum,
  ) => {
    try {
      const response = await uploadImage({
        file,
        feature,
        userId: user?.userId!,
      });
      logEvent(CloudinaryEvent.UPLOAD_IMAGE_SUCCESS, {
        type,
        feature,
      });
      return response;
    } catch (error) {
      Sentry.captureException(error);
      logEvent(CloudinaryEvent.UPLOAD_IMAGE_FAIL, {
        type,
        feature,
      });
    }
  };
  return { uploadImageToCloudinary };
};

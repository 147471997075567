import { getPublicConfig } from "./getPublicConfig";
import { i18n } from "next-i18next";

const { princess } = getPublicConfig();

export const getUserFolder = (userId: string | number) => {
  const id = `${userId}`.padStart(8, "0");

  return `${princess.serverUrl}/uploads/${id.slice(0, 4)}/${id.slice(-4)}/`;
};

const getCountryString = (city: string) => {
  if (!city) return "";

  return i18n?.t(`location:country.${city.slice(0, 2)}`) || "";
};

export const getLocationString = (
  city: string,
  options?: {
    hideSubdivision?: boolean;
    hideCountry?: boolean;
  },
) => {
  if (!city) return "";

  const [country, subdivision] = city.split("_");

  const subdivisionStr = i18n?.t(
    `location:subdivision.${country}_${subdivision}`,
  );

  const locationStringArr: string[] = [];

  if (
    subdivisionStr &&
    !subdivisionStr?.includes("subdivision.") &&
    !options?.hideSubdivision
  ) {
    locationStringArr.push(subdivisionStr);
  }
  if (!options?.hideCountry) {
    locationStringArr.push(getCountryString(city));
  }

  return locationStringArr.join(", ");
};

export const AvatarTypes = [
  "AVATAR_HEAD",
  "AVATAR_HALF",
  "AVATAR_FULL",
] as const;

export const getFileByObjectUrl = async (
  objectUrl: string,
  fileName: string,
) => {
  return await fetch(objectUrl)
    .then((r) => r.blob())
    .then(
      (blobFile) =>
        new File([blobFile], fileName, {
          type: blobFile.type,
        }),
    );
};

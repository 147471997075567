import { OnboardingDialog } from "@/features/auth/components/dialogs/OnboardingDialog";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { UserStatusEnum } from "@princess/graphql-codegen/graphql-react-query";
import { Fragment, type ReactNode, useEffect, useState } from "react";

type OnboardingWrapperProps = {
  children: ReactNode;
};
export const OnboardingWrapper = ({ children }: OnboardingWrapperProps) => {
  const { user } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.status === UserStatusEnum.Verified && !user.dob) {
      setIsOpen(true);
    }
  }, [user]);

  return (
    <Fragment>
      {children}
      <OnboardingDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Fragment>
  );
};

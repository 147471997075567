import { makeStyles } from "@material-ui/core";
import MuiPaper, { type PaperProps } from "@material-ui/core/Paper";
import { type FC, forwardRef } from "react";

const useStyles = makeStyles((theme) => ({
  borderPaper: {
    boxShadow: "0px 0px 0px 0px",
    borderColor: theme.palette.primary.light,
    borderStyle: "solid",
    borderWidth: 1,
  },
}));

/**
 *  @deprecated this is using material UI
 */
export const BorderPaper: FC<PaperProps> = forwardRef(
  ({ className, ...props }: PaperProps, ref) => {
    const classes = useStyles();

    return (
      <MuiPaper
        ref={ref}
        className={`${classes.borderPaper} ${className}`}
        {...props}
      />
    );
  },
);

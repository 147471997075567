import { Route } from "@/consts/Route";
import { useGraphqlClient } from "@/contexts/graphqlClientContext";
import { useGraphqlReactQueryUtils } from "@/contexts/useGraphqlReactQueryUtils";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useUserAcceptTermsMutation } from "@princess/graphql-codegen/nexus-types";
import { Button } from "@princess/ui/button";
import { isBefore } from "date-fns";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineX } from "react-icons/hi";

const { features } = getPublicConfig();

export function TncAcceptUpdateOverlay() {
  const [open, setOpen] = useState(false);
  const [termAccpeted, setTermAccepted] = useState(false);

  const { t } = useTranslation("common");
  const { user, refetchUser } = useUserContext();
  const { context } = useGraphqlReactQueryUtils();
  const { nexusGraphqlClient } = useGraphqlClient();
  const { mutateAsync } = useUserAcceptTermsMutation(nexusGraphqlClient, {
    context,
  });

  async function acceptTerms() {
    setOpen(false);
    setTermAccepted(true);
    await mutateAsync({});
    refetchUser();
  }

  useEffect(() => {
    if (!user) return;
    if (
      // open overlay if no record on user accepting terms
      user.tncAcceptedAt &&
      // terms last update is before user temrs accepted time
      isBefore(
        new Date(features.termOfService.lastUpdatedAt),
        new Date(user.tncAcceptedAt),
      )
    ) {
      return;
    }

    setOpen(true);
  }, [user]);

  if (!user || !open || termAccpeted) return null;

  return (
    <div className="fixed bottom-0 z-50 mx-2 mb-16 max-w-2xl self-center rounded-lg border border-gray-100 bg-white p-6 shadow-lg dark:bg-gray-800 lg:mb-4">
      <div className="absolute right-2 top-2">
        <Button variant="ghost" onClick={() => setOpen(false)}>
          <HiOutlineX />
        </Button>
      </div>

      <div className="space-y-4">
        <div>
          <h3 className="text-lg font-semibold">
            {t("tnc_privacy.update_dialog_title")}
          </h3>
          <p className="text-gray-500 dark:text-gray-400">
            <Trans i18nKey="tnc_privacy.update_dialog_desc">
              <Link className="text-primary" href={Route.Tnc}>
                {t("tnc_privacy.tnc")}
              </Link>
            </Trans>
          </p>
        </div>
        <div className="flex justify-end gap-2">
          <Button onClick={acceptTerms}>{t("tnc_privacy.agree")}</Button>
        </div>
      </div>
    </div>
  );
}

import { getFileByObjectUrl } from "@/helpers/utils";
import { useCallback, useMemo } from "react";
import * as Sentry from "@sentry/nextjs";
import {
  type CloudinaryResponse,
  FeatureEnum,
} from "@/services/cloudinary/uploadImages";
import { useUploadImageCloudinary } from "@/helpers/useUploadImageCloudinary";
import { useSnackbar } from "notistack";
import { CloudinaryImageType } from "@princess/helpers/src/cloudinary";

export const useAvatarUpload = () => {
  const { uploadImageToCloudinary } = useUploadImageCloudinary();
  const { enqueueSnackbar } = useSnackbar();

  const uploadAvatarToCloudinary = useCallback(
    async (file: File, type: CloudinaryImageType) => {
      const response = await uploadImageToCloudinary(
        file,
        type,
        FeatureEnum.Avatar,
      );

      if (!response) return;

      return response;
    },
    [uploadImageToCloudinary],
  );

  const uploadAvatarsToCloudinary = useCallback(
    async ({
      avatarHead,
      avatarHalf,
      avatarFull,
      userId,
    }: {
      avatarHead?: string;
      avatarHalf?: string;
      avatarFull?: string;
      userId: string;
    }) => {
      const avatarFile = avatarHead
        ? await getFileByObjectUrl(avatarHead, `${userId}_avatar`)
        : undefined;
      const avatarHalfFile = avatarHalf
        ? await getFileByObjectUrl(avatarHalf, `${userId}_avatar_half`)
        : undefined;
      const avatarFullFile = avatarFull
        ? await getFileByObjectUrl(avatarFull, `${userId}_avatar_full`)
        : undefined;

      const files = [
        { file: avatarFile, type: CloudinaryImageType.AVATAR_HEAD },
        { file: avatarHalfFile, type: CloudinaryImageType.AVATAR_HALF },
        { file: avatarFullFile, type: CloudinaryImageType.AVATAR_FULL },
      ] as const;

      try {
        const [avatarResponse, avatarHalfResponse, avatarFullResponse] =
          (await Promise.all(
            files.map(({ file, type }) =>
              file ? uploadAvatarToCloudinary(file, type) : Promise.resolve(),
            ),
          )) as [
            CloudinaryResponse | undefined,
            CloudinaryResponse | undefined,
            CloudinaryResponse | undefined,
          ];

        return {
          avatarResponse,
          avatarHalfResponse,
          avatarFullResponse,
        };
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar((error as Error).message);
      }
    },
    [enqueueSnackbar, uploadAvatarToCloudinary],
  );

  return useMemo(
    () => ({
      uploadAvatarsToCloudinary,
      uploadAvatarToCloudinary,
    }),
    [uploadAvatarToCloudinary, uploadAvatarsToCloudinary],
  );
};

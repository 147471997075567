import { cn } from "@princess/ui";
import { Text } from "@princess/ui/custom/text";

interface Props {
  currentLength: number;
  maxLength: number;
  className?: string;
}

export function InputLength({ currentLength, maxLength, className }: Props) {
  return (
    <Text
      className={cn(
        "mr-1 text-sm font-normal leading-none text-gray-400",
        className,
      )}
    >
      {`${currentLength} / ${maxLength}`}
    </Text>
  );
}

import { AuthVerificationCodeInput } from "@/features/auth/components/AuthVerificationCodeInput";
import { useErrorHandling } from "@/helpers/useErrorHandling";
import { FormBuilder } from "@/modules/form-builders/FormBuilder";

import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useEmailAuth } from "@/oldFeatures/auth/hooks/useEmailAuth";
import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useMemo, useState, type ComponentProps } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Button } from "@princess/ui/button";
import { FormLabel } from "@princess/ui/form";

export const EmailRegisterForm = () => {
  const { t } = useTranslation();
  const { handleGraphqlError } = useErrorHandling();
  const { refetchUser } = useUserContext();
  const { closeAuthDialog } = usePrincessAuthDialogContext();
  const { handleRegisterVerify } = useEmailAuth();

  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState<
    string | null
  >(null);

  const formFields = useMemo(
    () =>
      ({
        email: {
          label: t("auth.email"),
          required: true,
          type: "email",
          placeholder: "",
        },
        password: {
          label: t("auth.password"),
          required: true,
          type: "password",
          placeholder: "",
          validation: z
            .string()
            .min(8, t("auth.password_min_length", { length: 8 })),
        },
        confirmPassword: {
          label: t("auth.confirm_password"),
          required: true,
          type: "password",
          validation: z
            .string()
            .min(8, t("auth.password_min_length", { length: 8 })),
          placeholder: "",
        },
      }) satisfies ComponentProps<typeof FormBuilder>["fields"],
    [t],
  );

  const formComponents = useMemo<
    ComponentProps<typeof FormBuilder>["components"]
  >(
    () => ({
      button: (props) => <Button {...props} className="flex-1" />,
    }),
    [],
  );

  const onSubmit = async () => {
    if (!verificationCode) {
      setVerificationCodeError(
        t("common:validate.input_valid", {
          field: t("common:auth.verify_code"),
        }),
      );

      return;
    }

    try {
      await handleRegisterVerify({ verifyCode: verificationCode });

      await refetchUser();
      closeAuthDialog();
    } catch (e: any) {
      handleGraphqlError({ error: e });
    }
  };

  return (
    <FormBuilder
      mode="onChange"
      layout={["email", "password", "confirmPassword"]}
      fields={formFields}
      onSubmit={onSubmit}
      actionButtonLabel={t("auth.register")}
      components={formComponents}
      schemaExtension={(schema) =>
        schema.refine(
          ({ password, confirmPassword }) => password === confirmPassword,
          {
            path: ["confirmPassword"],
            message: t("auth.confirm_password_not_same_error"),
          },
        )
      }
      footer={
        <VerificationFormInput
          value={verificationCode}
          error={verificationCodeError}
          onChange={setVerificationCode}
        />
      }
      submitOnValid
    />
  );
};

type VerificationFormInputProps = {
  value: string;
  error: string | null;
  onChange: (value: string) => void;
};
const VerificationFormInput = ({
  value,
  error,
  onChange,
}: VerificationFormInputProps) => {
  const { t } = useTranslation();
  const { getValues, getFieldState } = useFormContext();
  const { handleEmailRegister } = useEmailAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { handleGraphqlError } = useErrorHandling();

  const { invalid: invalidEmail, isDirty: isEmailDirty } =
    getFieldState("email");
  const { invalid: invalidPassword, isDirty: isPasswordDirty } =
    getFieldState("confirmPassword");
  const allowSendVerificationCode =
    !invalidEmail && isEmailDirty && !invalidPassword && isPasswordDirty;

  const handleSendVerification = useCallback(async () => {
    const [email, password] = getValues(["email", "password"]);

    return handleEmailRegister({
      email,
      password,
    });
  }, [getValues, handleEmailRegister]);

  return (
    <div className="space-y-1">
      <FormLabel>{t("auth.verify_code")}</FormLabel>
      <AuthVerificationCodeInput
        value={value}
        error={error}
        onChange={(e) => onChange(e.target.value)}
        handleSendVerification={handleSendVerification}
        handleSendVerificationError={(error) => {
          if (error instanceof Error) {
            handleGraphqlError({ error });
          }
        }}
        handleSendVerificationSuccess={() => {
          enqueueSnackbar(t("auth.sent_verify_code"), { variant: "success" });
        }}
        sendVerificationButtonDisabled={!allowSendVerificationCode}
        renderButtonLabel={({ countdown, isCountdownRunning }) =>
          t("auth.send_verify_code") +
          (isCountdownRunning ? ` (${countdown}s)` : "")
        }
      />
    </div>
  );
};

import { InstallAppBadge } from "@/featuresPrincess/common/InstallAppBadge";
import { useTranslation } from "next-i18next";
import { Text } from "@princess/ui/custom/text";
import Link from "next/link";
import { Route } from "@/consts";
import { useDomainContext } from "@/helpers/useDomainContext";

export const Footer = () => {
  const { t } = useTranslation("common");
  const { isMillionBB } = useDomainContext();
  return (
    <div className="z-50 mt-auto flex w-full flex-col items-center gap-2 bg-gray-100 py-4">
      <div className="flex gap-2">
        <InstallAppBadge os="ios" variant="small" />
        <InstallAppBadge os="android" variant="small" />

        {/* divider */}
        <div className="h-5 w-[1px] bg-gray-300"></div>

        <div className="text-sm text-gray-700">
          <Link
            className="underline hover:opacity-70"
            href={Route.Tnc}
            rel="noreferrer noopener"
          >
            {t("footer.tnc")}
          </Link>
          <Text size="sm">{t("and")}</Text>
          <Link
            className="underline hover:opacity-70"
            href={Route.Privacy}
            rel="noreferrer noopener"
          >
            {t("footer.privacy")}
          </Link>
        </div>
      </div>

      <Text size="sm">
        {t("footer.copyright", {
          name: isMillionBB ? "MillionBB" : "Princess Dating",
          year: new Date().getFullYear(),
        })}
      </Text>
    </div>
  );
};

import {
  ImageDisplay,
  type ImageDisplayProps,
} from "@/featuresPrincess/common/ImageDisplay";
import { type User } from "@princess/common/user/types";
import { UserStatusEnum } from "@princess/graphql-codegen/graphql-react-query";
import { cn } from "@princess/ui";

const AvatarPlaceholder = "/assets/images/profile_head.png";

type AvatarDisplayProps = {
  user: Pick<User, "status" | "avatar" | "avatarBanned">;
  src?: string | null;
  showAvatarBannedOverlay?: boolean;
  placeholder?: string;
  className?: string;
} & Omit<ImageDisplayProps, "src" | "placeholder">;

export const AvatarDisplay = ({
  user,
  src,
  showAvatarBannedOverlay = true,
  placeholder,
  className,
  ...imageDisplayProps
}: AvatarDisplayProps) => {
  const isDeletedUser = user.status === UserStatusEnum.Deleted;
  const isBannedUser = user.status === UserStatusEnum.Banned;

  const shouldReplaceAvatarWithBannedOverlay =
    !isDeletedUser &&
    !isBannedUser &&
    user.avatarBanned &&
    showAvatarBannedOverlay;

  return (
    <div
      className={cn(
        "relative overflow-hidden rounded-full border border-gray-300",
        className,
      )}
    >
      <ImageDisplay
        {...imageDisplayProps}
        src={
          isDeletedUser
            ? "/assets/images/deleted_user.jpg"
            : shouldReplaceAvatarWithBannedOverlay
              ? "/assets/images/banned_avatar_overlay.png"
              : src ?? user.avatar ?? placeholder ?? AvatarPlaceholder
        }
      />

      {isBannedUser && (
        <ImageDisplay
          alt="banned"
          className="absolute inset-0"
          size={imageDisplayProps.size}
          shape={imageDisplayProps.shape}
          src="/assets/images/banned_user_overlay.png"
        />
      )}
    </div>
  );
};

import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import constate from "constate";
import { GraphQLClient } from "graphql-request";
import { useMemo } from "react";

const {
  princess: { serverUrl },
} = getPublicConfig();

export const [GraphqlClientProvider, useGraphqlClient] = constate(() => {
  const { token: authToken } = useUserContext();

  const nexusGraphqlClient = useMemo(() => {
    return new GraphQLClient(`${serverUrl}/api/graphql`, {
      headers: authToken
        ? {
            authorization: authToken, // the userid token is the auth token
          }
        : undefined,
    });
  }, [authToken]);

  const graphqlClient = useMemo(() => {
    return new GraphQLClient(`${serverUrl}/server`, {
      headers: authToken
        ? {
            authorization: authToken, // the userid token is the auth token
          }
        : undefined,
    });
  }, [authToken]);

  return {
    /**
     * the nexus graphql client is used for the nexus graphql server
     */
    nexusGraphqlClient,
    /**
     * the graphql client is used for the graphql server
     */
    graphqlClient,
  };
});

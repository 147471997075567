import DropdownIcon from "public/assets/icons/dropdown.svg";
import LanguageIcon from "public/assets/icons/language.svg";
import { appLanguages } from "@/consts/appLanguage";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@princess/ui/dropdownMenu";
import { useAppLanguage } from "@/featuresPrincess/common/hooks/useAppLanguage";
import type { AppLanguage } from "@/types/appLanguage";
import clsx from "clsx";
import { useMemo } from "react";

export const LanguageOptionDropdown = () => {
  const { currentLanguage, changeLanguage } = useAppLanguage();

  const chipText = useMemo(
    () => appLanguages[currentLanguage].labelShort,
    [currentLanguage],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center gap-2">
          <div className="flex w-max items-center gap-1">
            <LanguageIcon className="h-6 w-6" />
            <span className="text-base font-normal text-gray-700">
              {chipText}
            </span>
          </div>
          <DropdownIcon className="h-3 w-3" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-0 max-w-fit">
        {Object.entries(appLanguages).map(([key, value]) => (
          <DropdownMenuItem
            key={key}
            onClick={() => changeLanguage(key as AppLanguage)}
          >
            <span
              className={clsx(
                currentLanguage === key ? "text-primary" : "text-gray-700",
              )}
            >
              {value.label}
            </span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

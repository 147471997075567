import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, {
  Fragment,
  type ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

export const DialogContentWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className="inline-block w-full overflow-hidden">{children}</div>;
};

interface TailwindBaseDialogProps {
  openBaseDialog: (props: {
    content: ReactNode;
    header?: string | ReactNode;
  }) => void;
  closeBaseDialog: () => void;
  DialogContentWrapper: typeof DialogContentWrapper;
}

const TailwindBaseDialogContext = createContext<TailwindBaseDialogProps>(
  {} as TailwindBaseDialogProps,
);

export const TailwindBaseDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [dialogHeader, setDialogHeader] = useState<string | ReactNode | null>(
    null,
  );

  const closeBaseDialog = () => {
    setDialogContent(null);
    setDialogHeader(null);
  };

  const openBaseDialog = ({
    content,
    header,
  }: {
    content: ReactNode;
    header?: string | ReactNode;
  }) => {
    setDialogContent(content);
    setDialogHeader(header);
  };

  return (
    <TailwindBaseDialogContext.Provider
      value={{
        openBaseDialog,
        closeBaseDialog,
        DialogContentWrapper,
      }}
    >
      <>
        <Transition appear show={!!dialogContent} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 max-h-screen max-w-[100vw] overflow-y-auto"
            onClose={closeBaseDialog}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="duration-300 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-200 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              {!!dialogContent && (
                <Transition.Child
                  as={Fragment}
                  enter="duration-300 ease-out"
                  enterFrom="scale-95 opacity-0"
                  enterTo="scale-100 opacity-100"
                  leave="duration-200 ease-in"
                  leaveFrom="scale-100 opacity-100"
                  leaveTo="scale-95 opacity-0"
                >
                  <div className="sm:min-w-md my-8 inline-block w-full max-w-4xl transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all sm:w-auto">
                    <div className=" relative flex w-full items-center justify-center border border-gray-200 p-2.5 text-xl text-gray-900">
                      {dialogHeader}
                      <div
                        className="touchable absolute right-4 top-3.5"
                        onClick={closeBaseDialog}
                      >
                        <XIcon className="h-5 w-5" />
                      </div>
                    </div>
                    {dialogContent}
                    {/* 123123123 */}
                  </div>
                </Transition.Child>
              )}
            </div>
          </Dialog>
        </Transition>
        {children}
      </>
    </TailwindBaseDialogContext.Provider>
  );
};

export const useTailwindBaseDialog = () =>
  useContext(TailwindBaseDialogContext);

// see https://github.com/WeMakeAppSolution/koljobs-web/blob/74ab6e1b1c74d13d26f4a821522c54ba583c8f62/src/services/cloudinary/uploadImages.ts

import { compressImage } from "@/helpers/compressImage";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import * as Sentry from "@sentry/nextjs";
import axios, { type AxiosProgressEvent } from "axios";
import { v4 as uuid } from "uuid";

const {
  service: { cloudinary },
  projectSlug,
  vercelEnv,
} = getPublicConfig();

export enum FeatureEnum {
  Avatar = "Avatar",
}

export type CloudinaryResponse = {
  access_mode: "public";
  asset_id: string;
  bytes: number;
  created_at: string;
  etag: string;
  existing: boolean;
  folder: string;
  format: string;
  public_id: string;
  tags: string[];
  height: number;
  width: number;
  url: string;
  secure_url: string;
  version: number;
  version_id: string;
};

type UploadImageParam = {
  file: File;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  feature: FeatureEnum;
  userId: string;
  tags?: string[];
};

export const uploadImage = async ({
  file,
  onUploadProgress,
  feature,
  userId,
  tags = [],
}: UploadImageParam) => {
  try {
    const publicId = `${feature}/${userId}/${uuid()}`;
    const compressedFile = await compressImage(file);
    const formData = new FormData();
    formData.append("file", compressedFile);
    formData.append(
      "tags",
      [
        `vercelEnv:${vercelEnv}`,
        `project:${projectSlug}`,
        `feature:${feature}`,
        `userId:${userId}`,
        ...tags,
      ].join(","),
    );
    formData.append("upload_preset", cloudinary.uploadPreset);
    formData.append("public_id", publicId);
    const response = await axios.post(cloudinary.uploadEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
    return response.data as CloudinaryResponse;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag("action", "cloudinary.uploadImages");
      Sentry.captureException(error);
    });
    throw error;
  }
};

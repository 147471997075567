import { Route } from "@/consts";
import { phoneAreaCodes } from "@/consts/phoneAreaCodes";
import { useErrorHandling } from "@/helpers/useErrorHandling";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { usePhoneAuth } from "@/oldFeatures/auth/hooks/usePhoneAuth";
import { FormButton } from "@/oldFeatures/tailwind/components/FormButton";
import { LoadingSpinner } from "@/oldFeatures/tailwind/components/LoadingSpinner";
import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { SelectInput } from "@/oldFeatures/tailwind/components/input/SelectInput";
import { TextInput } from "@/oldFeatures/tailwind/components/input/TextInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { differenceInSeconds } from "date-fns";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, useForm } from "react-hook-form";
import { useAsyncFn, useInterval } from "react-use";
import { z } from "zod";

const useSchema = () => {
  const { t } = useTranslation("common");
  return z.object({
    areaCode: z.string().min(1),
    phone: z.string().min(
      1,
      t("auth.required_error", {
        field: t("auth.phone"),
      }),
    ),
    verifyCode: z.string().min(
      1,
      t("auth.required_error", {
        field: t("auth.verify_code"),
      }),
    ),
  });
};

export const PhoneContinueForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation("common");
  const { closeAuthDialog } = usePrincessAuthDialogContext();
  const { handleGraphqlError } = useErrorHandling();
  const { enqueueSnackbar } = useSnackbar();
  const { refetchUser } = useUserContext();
  const { handlePhoneSendVerifyCode, handlePhoneLoginVerify } = usePhoneAuth();
  const router = useRouter();
  const schema = useSchema();
  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { areaCode: "+852", phone: "", verifyCode: "" },
    mode: "onChange",
  });

  const [sentVerify, setSentVerify] = useState(false);

  const [lastSentVerify, setLastSentVerify] = useState<Date | null>(null);
  const [now, setNow] = useState(new Date());
  const secondsBeforeResend = lastSentVerify
    ? 60 - differenceInSeconds(now, lastSentVerify)
    : null;

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  const canSendVerifyCode =
    !errors.phone && (!secondsBeforeResend || secondsBeforeResend <= 0);

  const [{ loading: sendingVerify }, handleSendVerify] =
    useAsyncFn(async () => {
      try {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }

        const recaptchaToken = await executeRecaptcha(
          "princess_web/phone_verify",
        );

        const { phone, areaCode } = getValues();

        await handlePhoneSendVerifyCode({
          phone: areaCode + phone,
          recaptchaToken,
        });
        setSentVerify(true);
        enqueueSnackbar(t("auth.sent_verify_code"), { variant: "success" });
        setLastSentVerify(new Date());
      } catch (e: any) {
        setSentVerify(false);
        handleGraphqlError({ error: e });
      }
    }, [executeRecaptcha]);

  const onSubmit = handleSubmit(async (values) => {
    const { phone, areaCode, verifyCode } = values;
    try {
      await handlePhoneLoginVerify({ phone: areaCode + phone, verifyCode });
      await refetchUser();
      closeAuthDialog();
      router.push(Route.MemberHome);
    } catch (e: any) {
      handleGraphqlError({ error: e });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col space-y-4">
        <div className="flex w-full items-end space-x-4">
          <Controller
            render={({ field: { value, onChange } }) => {
              return (
                <SelectInput
                  className="w-32"
                  label={t("auth.phone")}
                  options={phoneAreaCodes}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
            control={control}
            name="areaCode"
          />

          <TextInput
            className="flex-1"
            label=""
            {...register("phone")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (canSendVerifyCode) handleSendVerify();
              }
            }}
          />
        </div>

        <div className="flex w-full items-end space-x-3">
          <TextInput
            disabled={!sentVerify}
            className="flex-1"
            label={t("auth.verify_code")}
            hasError={!!errors.verifyCode?.message}
            helperText={errors.verifyCode?.message}
            {...register("verifyCode", {
              required: t("auth.required_error") as string,
            })}
          />
          <FormButton
            disabled={!canSendVerifyCode}
            type="button"
            className="flex-1"
            variant="outlined"
            onClick={handleSendVerify}
          >
            {sendingVerify && <LoadingSpinner className="text-white" />}
            {t("auth.send_verify_code") +
              (secondsBeforeResend && secondsBeforeResend > 0
                ? ` (${secondsBeforeResend})`
                : "")}
          </FormButton>
        </div>
        <FormButton
          disabled={!sentVerify || !isValid || isSubmitting}
          type="submit"
          variant="filled"
          className="flex-1"
        >
          {isSubmitting && <LoadingSpinner className="text-white" />}
          {t("auth.login")}
        </FormButton>
      </div>
    </form>
  );
};

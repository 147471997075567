import { getPublicConfig } from "@/helpers/getPublicConfig";
import { getPublicId } from "@/services/cloudinary/getPublicId";
import { Cloudinary } from "@cloudinary/url-gen";
import { Resize } from "@cloudinary/url-gen/actions";

const {
  service: {
    cloudinary: { cloudName },
  },
} = getPublicConfig();

const cld = new Cloudinary({
  cloud: {
    cloudName,
  },
  url: {
    secure: true,
  },
});

export const getTransformedUrl = (
  url: string,
  width: number,
  height = width,
) => {
  const publicId = getPublicId(url);
  const image = cld.image(publicId);
  const resizedImage = image.resize(
    Resize.fit().width(Math.round(width)).height(Math.round(height)),
  );
  return resizedImage.toURL();
};
